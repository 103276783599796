<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Pelatihan</v-tab>
          <v-tab>Kegiatan</v-tab>
          <v-tab>Peserta</v-tab>
          <v-tab>Invoice</v-tab>
          <v-tab>Komentar</v-tab>
          <v-tab>Pertanyaan</v-tab>
          <v-tab>Sertifikat</v-tab>
          <v-tab>Evaluasi Fasilitator</v-tab>
          <v-tab>Evaluasi Penyelenggaraan</v-tab>
          <v-tab>Evaluasi Pasca Kegiatan</v-tab>
          <v-tab>Laporan</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pl-4 pr-4 pt-4 pb-4">
              <v-card-title> Pelatihan </v-card-title>
              <v-fab-transition>
                <v-btn
                  v-if="isEditable == 0"
                  class="mt-8 mr-4"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                  @click="changeEditable"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      v-model="currentData.file_foto"
                      accept="image/*"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                      @change="loadImage"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="currentData.kegiatan_title"
                          outlined
                          name="input-7-4"
                          label="Judul"
                          dense
                          value=""
                          rows="3"
                          :rules="currentData.kegiatanTitleRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="currentData.kegiatan_desc"
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          :rules="currentData.kegiatanDescRules"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model="currentData.informasi"
                          outlined
                          name="input-7-4"
                          label="Informasi"
                          dense
                          value=""
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Konten</h5>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <v-textarea
                      v-model="currentData.prasyarat_khusus"
                      outlined
                      name="input-7-4"
                      label="Prasyarat Khusus"
                      dense
                      value=""
                      :readonly="isEditable == 0"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.harga"
                          label="Harga"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.nomor_skp"
                          label="No SKP"
                          outlined
                          dense
                          :readonly="isEditable == 0"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.tanggal_awal"
                          label="Tanggal Awal"
                          type="date"
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.tanggal_akhir"
                          label="Tanggal Akhir"
                          type="date"
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="currentData.tanggal_evaluasi_pasca_kegiatan"
                          label="Tanggal Evaluasi Pasca Kegiatan"
                          type="date"
                          dense
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="currentData.skp_narasumber"
                      label="SKP Narasumber"
                      outlined
                      dense
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="currentData.skp_moderator"
                      label="SKP Moderator"
                      outlined
                      dense
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="currentData.skp_panitia"
                      label="SKP Panitia"
                      outlined
                      dense
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-text-field
                      v-model="currentData.skp_peserta"
                      label="SKP Peserta"
                      outlined
                      dense
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="currentData.penyelenggara"
                      label="Penyelenggara"
                      outlined
                      dense
                      required
                      :readonly="isEditable == 0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      v-model="currentData.selectedKategori"
                      label="Kategori"
                      clearable
                      dense
                      outlined
                      :items="items_kategori"
                      item-text="value"
                      :disabled="isEditable == 0"
                      :rules="currentData.kategoriRules"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      v-model="currentData.selectedDpd"
                      label="DPD"
                      clearable
                      dense
                      outlined
                      :items="items_dpd"
                      item-text="nama"
                      :rules="currentData.dpdRules"
                      :disabled="isEditable == 0"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      style="margin-right: 10px"
                      @click="doBatal"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <PelatihanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanPesertaComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InvoiceComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanKomentarComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanQuestionComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanSertifikatComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanEvaluasiFasilitatorComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanEvaluasiPenyelenggaraanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PelatihanEvaluasiPascaKegiatanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <LaporanComponent :id="id" :kegiatan-id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import KegiatanService from "../services/KegiatanService";
import MasterService from "../services/MasterService";
import * as moment from "moment";
import PelatihanComponent from "./pelatihan/PelatihanList.vue";
import PelatihanPesertaComponent from "./pelatihan/PelatihanPeserta.vue";
import InvoiceComponent from "./pelatihan/InvoicePelatihan.vue";
import PelatihanKomentarComponent from "./pelatihan/PelatihanKomentar.vue";
import PelatihanQuestionComponent from "./pelatihan/PelatihanQuestion.vue";
import PelatihanSertifikatComponent from "./pelatihan/PelatihanSertifikat.vue";
import PelatihanEvaluasiFasilitatorComponent from "./pelatihan/PelatihanEvaluasiFasilitator.vue";

import PelatihanEvaluasiPenyelenggaraanComponent from "./pelatihan/PelatihanEvaluasiPenyelenggaraan.vue";
import PelatihanEvaluasiPascaKegiatanComponent from "./pelatihan/PelatihanEvaluasiPascaKegiatan.vue";
import LaporanComponent from "./report_result/DataReportResult.vue";

// import KomisariatService from "../services/KomisariatService";
import DPDService from "../services/DPDService";
// import store from "../store/index";

export default {
  name: "KegiatanEdit",
  components: {
    PelatihanComponent,
    PelatihanPesertaComponent,
    PelatihanKomentarComponent,
    PelatihanQuestionComponent,
    PelatihanSertifikatComponent,
    PelatihanEvaluasiPenyelenggaraanComponent,
    PelatihanEvaluasiPascaKegiatanComponent,
    PelatihanEvaluasiFasilitatorComponent,
    LaporanComponent,
    InvoiceComponent,
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Pelatihan",
        disabled: false,
        exact: true,
        to: { name: "Pelatihan" },
      },
      { text: "Edit Pelatihan", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      tanggal_evaluasi_pasca_kegiatan: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      // selectedKomisariat: "",
      selectedDpd: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
      dpdRules: [(v) => !!v || "DPD harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
    },
    items_kategori: [],
    // items_komisariat: [],
    items_dpd: [],
    fotoKey: 0,
  }),
  computed: {
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted: async function () {
    this.getListKategoriKegiatan();
    this.getListDpd();
    // this.getListKomisariat();
    this.getKegiatan();
  },
  methods: {
    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.currentData.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getListDpd() {
      DPDService.dpdListAllOnly()
        .then((res) => {
          this.items_dpd = res.data.list_data;

          // if (this.getKomisariatId) {
          //   this.items_komisariat.forEach((entry) => {
          //     if (entry.id == this.getKomisariatId) {
          //       this.currentData.selectedKomisariat = entry;
          //       this.getDataFromApi();
          //     }
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    getKegiatan() {
      KegiatanService.getKegiatan(this.id)
        .then((res) => {
          console.log(res.data.kegiatan);
          // this.currentData = res.data.kegiatan;
          this.currentData.kegiatan_title = res.data.kegiatan.kegiatan_title;
          this.currentData.kegiatan_desc = res.data.kegiatan.kegiatan_desc;
          this.currentData.informasi = res.data.kegiatan.informasi;
          this.currentData.prasyarat_khusus =
            res.data.kegiatan.prasyarat_khusus;
          this.currentData.harga = res.data.kegiatan.harga;
          this.currentData.nomor_skp = res.data.kegiatan.nomor_skp;
          this.currentData.skp_narasumber = res.data.kegiatan.skp_narasumber;
          this.currentData.skp_moderator = res.data.kegiatan.skp_moderator;
          this.currentData.skp_panitia = res.data.kegiatan.skp_panitia;
          this.currentData.skp_peserta = res.data.kegiatan.skp_peserta;
          this.currentData.penyelenggara = res.data.kegiatan.penyelenggara;

          this.currentData.tanggal_awal = moment(
            res.data.kegiatan.tanggal_awal,
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            res.data.kegiatan.tanggal_akhir,
          ).format("YYYY-MM-DD");

          if (res.data.kegiatan.tanggal_evaluasi_pasca_kegiatan) {
            this.currentData.tanggal_evaluasi_pasca_kegiatan = moment(
              res.data.kegiatan.tanggal_evaluasi_pasca_kegiatan,
            ).format("YYYY-MM-DD");
          }

          if (res.data.kegiatan.kategori) {
            this.items_kategori.forEach((entry) => {
              if (entry.code == res.data.kegiatan.kategori) {
                this.currentData.selectedKategori = entry;
              }
            });
          }

          if (res.data.kegiatan.dpd_id) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == res.data.kegiatan.dpd_id) {
                this.currentData.selectedDpd = entry;
              }
            });
          }

          this.currentData.FotoUrl = res.data.kegiatan.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.update(
        this.id,
        this.currentData,
        this.currentData.file_foto,
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Pelatihan berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Edit Pelatihan gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
