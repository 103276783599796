<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Report Absensi

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-4"
            @click="onDownloadAbsensi()"
          >
            Download
          </v-btn>
        </v-card-title>

        <v-dialog v-model="dialogDatePicker" max-width="350px">
          <v-card>
            <v-card-title class="text-h5"
              ><v-date-picker
                v-model="search.tanggal_pick"
                @change="changeTanggal"
              ></v-date-picker
            ></v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogDatePicker = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-row class="mx-6 mb-6">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.nama"
              outlined
              dense
              append-icon="mdi-magnify"
              label="Cari nama"
              single-line
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.npm"
              outlined
              dense
              append-icon="mdi-magnify"
              label="Cari NPM"
              single-line
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.selectedFakultas"
              label="Fakultas"
              clearable
              dense
              outlined
              :items="items_fakultas"
              item-text="name"
              :disabled="isAdminFakultas"
              @change="changeFakultas"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.selectedStase"
              label="Stase"
              clearable
              dense
              outlined
              :items="items_stase"
              item-text="name"
              @change="changeStase"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.tahun"
              label="Tahun"
              clearable
              dense
              outlined
              :items="items_tahun"
              item-text="name"
              @change="getDataFromApi"
            ></v-combobox
          ></v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="search.bulan"
              label="Bulan"
              clearable
              dense
              outlined
              :items="items_bulan"
              item-text="name"
              @change="getDataFromApi"
            ></v-combobox
          ></v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search.tanggal"
              outlined
              dense
              append-icon="mdi-magnify"
              label="Tanggal"
              single-line
              hide-details
              readonly
              clearable
              @click="dialogDatePicker = true"
              @click:clear="clearTanggal"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.tanggal`]="{ item }">
            <span>{{ getCurrentDate(item.tanggal) }}</span>
          </template>
          <template #[`item.tanggal_awal`]="{ item }">
            <div>
              <span>{{ getCurrentHour(item.tanggal_awal) }}</span>
            </div>
            <div v-if="item.is_terlambat == 2">
              <v-badge color="error" content="Terlambat" inline></v-badge>
            </div>
          </template>
          <template #[`item.tanggal_akhir`]="{ item }">
            <div>
              <span>{{ getCurrentHour(item.tanggal_akhir) }}</span>
            </div>
            <!-- <div>
              <v-badge color="error" content="Kurang" inline></v-badge>
            </div> -->
          </template>
          <template #[`item.range`]="{ item }">
            <span v-if="item.range">{{ item.range }}</span>
          </template>
          <template #[`item.photo_datang`]="{ item }">
            <v-btn
              v-if="item.photo_datang_original"
              color="blue"
              dark
              class="mb-2"
              @click="showFoto(item.photo_datang)"
            >
              View
            </v-btn>
          </template>
          <template #[`item.photo_pulang`]="{ item }">
            <v-btn
              v-if="item.photo_pulang_original"
              color="blue"
              dark
              class="mb-2"
              @click="showFoto(item.photo_pulang)"
            >
              View
            </v-btn>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              v-if="
                item.status == 2 && (item.is_approver == 1 || getRoleId == 1)
              "
              medium
              class="mr-3"
              @click="approveItem(item)"
            >
              mdi-check
            </v-icon>
            <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="dialogPhoto" max-width="600px">
        <v-card>
          <v-img height="auto" width="auto" :src="fotoAbsen"></v-img>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import AbsensiService from "../services/AbsensiService";
import CommonService from "../services/CommonService";
import NakesService from "../services/NakesService";
import StaseService from "../services/StaseService";
import FakultasService from "../services/FakultasService";
import * as moment from "moment";

import store from "../store/index";

export default {
  name: "AbsensiPegawai",
  components: {},
  data: () => ({
    isProgressBarShow: false,
    dialogDatePicker: false,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Report Absensi",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      npm: "",
      tahun: "",
      tanggal: "",
      tanggal_pick: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
      selectedStase: "",
      selectedFakultas: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "NPM", value: "npm", sortable: false },
      { text: "Stase", value: "stase_name", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "Jam Awal", value: "tanggal_awal", sortable: false },
      { text: "Jam Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Range", value: "range", sortable: false },
      { text: "Foto Datang", value: "photo_datang", sortable: false },
      { text: "Foto Pulang", value: "photo_pulang", sortable: false },
      // { text: "Range", value: "range", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,

    dialogPhoto: false,
    fotoAbsen: null,

    items_cabang: [],
    items_tahun: [],
    items_stase: [],
    items_fakultas: [],
    items_bulan: [
      {
        id: 1,
        name: "Januari",
      },
      {
        id: 2,
        name: "Februari",
      },
      {
        id: 3,
        name: "Maret",
      },
      {
        id: 4,
        name: "April",
      },
      {
        id: 5,
        name: "Mei",
      },
      {
        id: 6,
        name: "Juni",
      },
      {
        id: 7,
        name: "Juli",
      },
      {
        id: 8,
        name: "Agustus",
      },
      {
        id: 9,
        name: "September",
      },
      {
        id: 10,
        name: "Oktober",
      },
      {
        id: 11,
        name: "November",
      },
      {
        id: 12,
        name: "Desember",
      },
    ],
  }),

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getFakultasId() {
      return store.getters.getUser.fakultas_id;
    },
    isAdminFakultas() {
      if (store.getters.getUser.fakultas_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.getListPropinsi();
    this.getListFakultas();
    this.getListStase();
    this.getDataFromApi();
  },
  methods: {
    showFoto(foto) {
      this.fotoAbsen = foto;
      this.dialogPhoto = true;
    },

    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;

          if (this.getFakultasId) {
            this.items_fakultas.forEach((entry) => {
              if (entry.id == this.getFakultasId) {
                this.search.selectedFakultas = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStase() {
      StaseService.staseAll()
        .then((res) => {
          this.items_stase = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeFakultas() {
      this.getDataFromApi();
    },

    changeStase() {
      this.getDataFromApi();
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getCurrentHour(tanggal) {
      if (!tanggal) return "";

      return moment(tanggal).format("HH:mm:ss");
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsiId = "";
      var tanggal = "";
      var bulan = "";
      var stase = "";
      var fakultas = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.bulan) {
        bulan = this.search.bulan.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsiId = this.search.selectedPropinsi.id;
      }

      if (this.search.selectedStase) {
        stase = this.search.selectedStase.id;
      }

      if (this.search.selectedFakultas) {
        fakultas = this.search.selectedFakultas.id;
      }

      if (this.search.tanggal) {
        tanggal = moment(this.search.tanggal, "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        );
      }

      AbsensiService.absensiList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.npm,
        tahun,
        kategori,
        status,
        propinsiId,
        tanggal,
        bulan,
        stase,
        fakultas,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    deleteItemConfirm() {
      this.closeDelete();

      AbsensiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeTanggal(tanggal) {
      console.log(tanggal);
      this.search.tanggal = moment(tanggal, "YYYY-MM-DD").format("DD/MM/YYYY");
      this.dialogDatePicker = false;
      this.getDataFromApi();
    },

    clearTanggal() {
      this.search.tanggal = "";
      this.getDataFromApi();
    },

    onDownloadAbsensi() {
      this.isProgressBarShow = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsiId = "";
      var tanggal = "";
      var bulan = "";
      var stase = "";
      var fakultas = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.bulan) {
        bulan = this.search.bulan.id;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsiId = this.search.selectedPropinsi.id;
      }

      if (this.search.selectedStase) {
        stase = this.search.selectedStase.id;
      }

      if (this.search.selectedFakultas) {
        fakultas = this.search.selectedFakultas.id;
      }

      if (this.search.tanggal) {
        tanggal = moment(this.search.tanggal, "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        );
      }

      AbsensiService.downloadAbsensiReport(
        this.search.nama,
        this.search.npm,
        tahun,
        kategori,
        status,
        propinsiId,
        tanggal,
        bulan,
        stase,
        fakultas,
      )
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },
  },
};
</script>
