import http from "../http-common";
/* eslint-disable */
class AbsensiService {
  absensiList(
    page,
    itemPerPage,
    keyword,
    npm,
    tahun,
    kategori,
    status,
    propinsiId,
    tanggal,
    bulan,
    stase,
    fakultas,
  ) {
    return http.get(
      "/absensi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&tahun=" +
        tahun +
        "&kategori=" +
        kategori +
        "&status=" +
        status +
        "&npm=" +
        npm +
        "&propinsi_id=" +
        propinsiId +
        "&tanggal=" +
        tanggal +
        "&bulan=" +
        bulan +
        "&stase=" +
        stase +
        "&fakultas_id=" +
        fakultas,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  downloadAbsensiReport(
    keyword,
    npm,
    tahun,
    kategori,
    status,
    propinsiId,
    tanggal,
    bulan,
    stase,
    fakultas,
  ) {
    return http.get(
      "/report/absensi/peserta-didik/download?keyword=" +
        keyword +
        "&tahun=" +
        tahun +
        "&kategori=" +
        kategori +
        "&status=" +
        status +
        "&npm=" +
        npm +
        "&propinsi_id=" +
        propinsiId +
        "&tanggal=" +
        tanggal +
        "&bulan=" +
        bulan +
        "&stase=" +
        stase +
        "&fakultas_id=" +
        fakultas,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  getInvoice(id) {
    return http.get(`/invoice/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new AbsensiService();
