import http from "../http-common";
/* eslint-disable */
class MasaStaseService {
  masaStaseList(page, itemPerPage, keyword, userId) {
    return http.get(
      "/masastase/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", data.user_id);

    if (data.selectedStase && data.selectedStase != undefined) {
      bodyFormData.append("stase_id", data.selectedStase.id);
    }

    bodyFormData.append("tanggal_awal", data.tanggal_awal);
    bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    return http.post("/masastase", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("user_id", data.user_id);

    if (data.selectedStase && data.selectedStase != undefined) {
      bodyFormData.append("stase_id", data.selectedStase.id);
    }

    bodyFormData.append("tanggal_awal", data.tanggal_awal);
    bodyFormData.append("tanggal_akhir", data.tanggal_akhir);

    return http.put(`/masastase/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/masastase/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new MasaStaseService();
