<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-card class="mt-4">
        <v-card-title>
          Dashboard
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-row dense class="mt-4">
        <v-col cols="12" sm="3" md="3">
          <v-card color="#17A2B8" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  <div>{{ totalPesertaDidik }}</div>
                </v-card-title>

                <v-card-subtitle>
                  <div>Jumlah Peserta Didik</div>
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn class="ml-2 mt-5" outlined rounded small>
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-card color="#28A745" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5"
                  ><div>{{ totalStase }}</div></v-card-title
                >

                <v-card-subtitle><div>Jumlah Stase</div></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(1)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" sm="3" md="3">
          <v-card color="#FFC107" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  :text="kegiatanSelesaiTitle"
                ></v-card-title>

                <v-card-subtitle
                  :text="kegiatanSelesaiSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(2)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col> -->
        <!-- <v-col cols="12" sm="3" md="3">
          <v-card color="#DC3545" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                  :text="kegiatanAkanDatangTitle"
                ></v-card-title>

                <v-card-subtitle
                  :text="kegiatanAkanDatangSubtitle"
                ></v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    rounded
                    small
                    @click="changeWaktu(3)"
                  >
                    Lihat
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
import DashboardService from "../services/DashboardService";
import MasterService from "../services/MasterService";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
// import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    // VChart,
  },
  provide: {
    // [THEME_KEY]: "light",
  },
  data: () => ({
    totalPesertaDidik: 0,
    totalStase: 0,
    search: "",
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    selectedKategori: "",
    selectedWaktu: "",
    items_kategori: [],
    items_waktu: [
      {
        code: 1,
        value: "Kegiatan Berlangsung",
      },
      {
        code: 2,
        value: "Kegiatan Selesai",
      },
      {
        code: 3,
        value: "Kegiatan akan Datang",
      },
    ],
  }),
  computed: {},
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getDashboard();
    this.getListKategoriKegiatan();
  },
  methods: {
    changeWaktu(val) {
      const el = this.$el.getElementsByClassName("scroll-to-me")[0];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }

      this.items_waktu.forEach((entry) => {
        if (entry.code == val) {
          this.selectedWaktu = entry;
        }
      });

      this.getDataFromApi();
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      console.log(this.selectedKategori);

      var param = null;
      var paramWaktu = null;

      if (this.selectedKategori) {
        param = this.selectedKategori.code;
      }

      if (this.selectedWaktu) {
        paramWaktu = this.selectedWaktu.code;
      }

      KegiatanService.kegiatanListByKategori(
        page,
        itemsPerPage,
        this.search,
        param,
        paramWaktu,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDashboard() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      DashboardService.getDashboard(page, itemsPerPage, this.search)
        .then((res) => {
          this.totalPesertaDidik = res.data.total_peserta_didik;
          this.totalStase = res.data.total_stase;

          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
