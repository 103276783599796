<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Pengaturan Jam Absensi </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="changeEditable"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="pl-4 pr-4 mt-4"
        >
          <div class="text-h7 font-weight-bold">Jam Absensi Seluruh Stase</div>
          <v-row class="mt-4" dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="jam_masuk_general"
                label="Jam Masuk"
                outlined
                dense
                type="time"
                :readonly="isEditable == 0"
                :rules="jamMasukGeneralRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="jam_keluar_general"
                label="Jam Keluar"
                outlined
                dense
                type="time"
                format="24hr"
                :readonly="isEditable == 0"
                :rules="jamKeluarGeneralRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <div class="text-h7 font-weight-bold">Jam Absensi Stase Forensik</div>
          <v-row class="mt-4" dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="jam_masuk_forensik"
                label="Jam Masuk"
                outlined
                dense
                type="time"
                format="24hr"
                :readonly="isEditable == 0"
                :rules="jamMasukForensikRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="jam_keluar_forensik"
                label="Jam Keluar"
                outlined
                dense
                type="time"
                format="24hr"
                :readonly="isEditable == 0"
                :rules="jamKeluarForensikRules"
              ></v-text-field>
            </v-col>
            <v-row> </v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                style="margin-right: 10px"
                @click="doBatal"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PengaturanJamAbsensiService from "../services/PengaturanJamAbsensiService";
import CommonService from "../services/CommonService";

export default {
  name: "PengaturanJamAbsensi",
  components: {},
  props: {},
  data: () => ({
    valid: false,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Pengaturan Jam Absensi",
        disabled: true,
        exact: true,
        to: { name: "Berita" },
      },
    ],
    jam_masuk_general: "",
    jam_keluar_general: "",
    jam_masuk_forensik: "",
    jam_keluar_forensik: "",
    jamMasukGeneralRules: [(v) => !!v || "Jam masuk seluruh stase harus diisi"],
    jamMasukForensikRules: [
      (v) => !!v || "Jam masuk stase forensik harus diisi",
    ],
    jamKeluarGeneralRules: [
      (v) => !!v || "Jam keluar seluruh stase harus diisi",
    ],
    jamKeluarForensikRules: [
      (v) => !!v || "Jam keluar stase forensik harus diisi",
    ],
  }),
  computed: {},
  mounted: async function () {
    this.jamAbsensi();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    jamAbsensi() {
      PengaturanJamAbsensiService.jamAbsensi()
        .then((res) => {
          this.jam_masuk_general = res.data.jam_masuk_general;
          this.jam_keluar_general = res.data.jam_keluar_general;
          this.jam_masuk_forensik = res.data.jam_masuk_forensik;
          this.jam_keluar_forensik = res.data.jam_keluar_forensik;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      console.log(this.jam_masuk_general);
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      PengaturanJamAbsensiService.update(
        this.jam_masuk_general,
        this.jam_keluar_general,
        this.jam_masuk_forensik,
        this.jam_keluar_forensik,
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update jam absensi berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);

          CommonService.showSuccessToast("Update jam absensi gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
