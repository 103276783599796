import http from "../http-common";
/* eslint-disable */
class InvoiceService {
  invoiceList(page, itemPerPage, keyword, kegiatanId) {
    return http.get(
      "/invoice/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_type=" +
        1 +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  invoiceIuranList(
    page,
    itemPerPage,
    keyword,
    invoiceNo,
    tahun,
    kategori,
    status,
    komisariat,
  ) {
    return http.get(
      "/invoice/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_type=" +
        2 +
        "&tahun=" +
        tahun +
        "&kategori=" +
        kategori +
        "&status=" +
        status +
        "&komisariat=" +
        komisariat +
        "&invoice_no=" +
        invoiceNo,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  invoiceItemList(page, itemPerPage, keyword, invoiceId) {
    return http.get(
      "/invoice-item/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_id=" +
        invoiceId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  kegiatanNonPesertaList(page, itemPerPage, keyword, kegiatanId) {
    return http.get(
      "/kegiatan-non-peserta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    return http.post("/kegiatan-peserta", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  konfirmasiPeserta(data) {
    return http.put(`/kegiatan-konfirmasi-peserta/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  gantiPassword(data) {
    return http.put(`/kegiatan-peserta/ganti-password/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  absensi(data) {
    return http.put(`/kegiatan-peserta/absensi/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  delete(id) {
    return http.delete(`/kegiatan-peserta/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  approve(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/invoice/approveiuran/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  reject(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/invoice/rejectiuran/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InvoiceService();
