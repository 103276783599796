<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Admin DPD
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus admin ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedKomisariat"
              label="Komisariat"
              clearable
              dense
              outlined
              :items="items_komisariat"
              item-text="nama"
              :disabled="isAdminKomisariat"
              @change="changeKomisariat"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <UserAdminComponent
          ref="mychild"
          :key="childKey"
          :refresh="getDataFromApi"
        />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AdminService from "../services/AdminDPDService";
import CommonService from "../services/CommonService";
import KomisariatService from "../services/KomisariatService";
import UserAdminComponent from "./UserAdminDPD.vue";
import store from "../store/index";

export default {
  components: {
    UserAdminComponent,
  },
  data: () => ({
    childKey: 0,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Admin",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedKomisariat: "",
    items_komisariat: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
  }),
  computed: {
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListKomisariat();
    this.getDataFromApi();
  },
  methods: {
    changeKomisariat() {
      this.getDataFromApi();
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAll()
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      this.loading = true;
      AdminService.adminList(page, itemsPerPage, this.search, komisariat)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      AdminService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus admin berhasil");

          this.getDataFromApi();
          this.$refs.mychild.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus admin gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
