<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Stase
          <v-spacer />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer /> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Stase</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="currentData.selectedFakultas"
                        label="Fakultas"
                        clearable
                        dense
                        outlined
                        :items="items_fakultas"
                        item-text="name"
                        :disabled="isAdminFakultas"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.name"
                        label="Nama"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedFakultas"
              label="Fakultas"
              clearable
              dense
              outlined
              :items="items_fakultas"
              item-text="name"
              :disabled="isAdminFakultas"
              @change="changeFakultas"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import StaseService from "../services/StaseService";
import FakultasService from "../services/FakultasService";
import CommonService from "../services/CommonService";
import store from "../store/index";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Stase",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedFakultas: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "name", sortable: false },
      { text: "Fakultas", value: "fakultas_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_fakultas: [],
    currentData: {
      id: null,
      name: "",
      selectedFakultas: "",
    },
  }),
  computed: {
    getFakultasId() {
      return store.getters.getUser.fakultas_id;
    },
    isAdminFakultas() {
      if (store.getters.getUser.fakultas_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListFakultas();
    this.getDataFromApi();
  },
  methods: {
    changeFakultas() {
      this.getDataFromApi();
    },
    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;

          if (this.getFakultasId) {
            this.items_fakultas.forEach((entry) => {
              if (entry.id == this.getFakultasId) {
                this.selectedFakultas = entry;
                this.currentData.selectedFakultas = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var fakultas = "";

      if (this.selectedFakultas) {
        fakultas = this.selectedFakultas.id;
      }

      this.loading = true;
      StaseService.staseList(page, itemsPerPage, this.search, fakultas)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.name = "";

      if (!this.getFakultasId) {
        this.currentData.selectedFakultas = "";
      }
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        StaseService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Stase berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah Stase gagal. Coba lagi");
          });
      } else {
        StaseService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Stase berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update Stase gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.name = this.editedItem.name;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.fakultas_id) {
        this.items_fakultas.forEach((entry) => {
          if (entry.id == this.editedItem.fakultas_id) {
            this.currentData.selectedFakultas = entry;
          }
        });
      }

      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      StaseService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Stase berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Stase gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
