import http from "../http-common";
/* eslint-disable */
class PengaturanJamAbsensiService {
  jamAbsensi() {
    return http.get(`/jam-absensi`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("judul", data.judul);
    bodyFormData.append("konten", data.konten);

    if (photo != null) bodyFormData.append("foto", photo);

    return http.post("/berita", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(
    jamMasukGeneral,
    jamKeluarGeneral,
    jamMasukForensik,
    jamKeluarForensik,
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("jam_masuk_general", jamMasukGeneral);
    bodyFormData.append("jam_keluar_general", jamKeluarGeneral);
    bodyFormData.append("jam_masuk_forensik", jamMasukForensik);
    bodyFormData.append("jam_keluar_forensik", jamKeluarForensik);

    return http.put(`/jam-absensi`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/berita/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PengaturanJamAbsensiService();
