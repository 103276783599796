<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-tabs>
          <v-tab>Iuran</v-tab>
          <v-tab>Master Iuran </v-tab>
          <v-tab>Master Rekening</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title>
                Iuran
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" persistent max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Iuran</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="pl-4 pr-4"
                      >
                        <v-container>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                v-model="currentData.selectedKategori"
                                label="Kategori"
                                clearable
                                dense
                                outlined
                                :items="items_kategori"
                                item-text="name"
                                :rules="currentData.kategoriRules"
                                @change="getMasterIuran"
                              ></v-combobox>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="currentData.nama"
                                label="Nama Anggota"
                                outlined
                                dense
                                required
                                readonly
                                :rules="currentData.namaRules"
                                @click="openAnggotaModal"
                                @change="getMasterIuran"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="
                              currentData.selectedKategori &&
                              currentData.selectedKategori.id == 4
                            "
                          >
                            <v-col cols="12">
                              <v-combobox
                                v-model="currentData.selectedCabang"
                                label="Cabang"
                                clearable
                                dense
                                outlined
                                :items="items_cabang"
                                item-text="name"
                                :rules="currentData.cabangRules"
                              ></v-combobox>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="currentData.no_npa"
                                label="No NPA"
                                readonly
                                outlined
                                dense
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                v-model="currentData.tahun"
                                label="Tahun"
                                clearable
                                dense
                                outlined
                                :items="items_tahun"
                                item-text="name"
                                :rules="currentData.tahunRules"
                                @change="getMasterIuran"
                              ></v-combobox>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              currentData.selectedKategori &&
                              currentData.selectedKategori.id != 2
                            "
                            dense
                          >
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="currentData.nominal_cabang"
                                label="Nominal Cabang"
                                outlined
                                dense
                                required
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              currentData.selectedKategori &&
                              currentData.selectedKategori.id != 2
                            "
                            dense
                          >
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="currentData.nominal_pusat"
                                label="Nominal Pusat"
                                outlined
                                dense
                                required
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="currentData.nominal"
                                :readonly="
                                  currentData.selectedKategori &&
                                  currentData.selectedKategori.id != 2
                                "
                                label="Nominal"
                                outlined
                                dense
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-file-input
                                v-model="currentData.foto_url"
                                accept="image/*"
                                prepend-icon="mdi-image"
                                show-size
                                label="Bukti Foto"
                                @change="loadImage"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-responsive
                                v-if="currentData.FotoUrl"
                                :aspect-ratio="16 / 9"
                              >
                                <v-img
                                  :key="currentData.fotoKey"
                                  :src="currentData.FotoUrl"
                                  contain
                                ></v-img>
                              </v-responsive>
                            </v-col>
                          </v-row>
                          <v-row dense class="mt-4">
                            <v-col cols="12">
                              <v-checkbox
                                v-model="currentData.langsung_setujui"
                                label="Langsung Setujui"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="doSave">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogReject" max-width="700px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mereject invoice ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeReject"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="rejectItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogUpdate" persistent max-width="600px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Pengajuan Mutasi</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="pl-4 pr-4"
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="currentData.nama"
                                label="Nama Anggota"
                                outlined
                                dense
                                required
                                readonly
                                :rules="currentData.namaRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="currentData.cabang_asal_nama"
                                label="Cabang Asal"
                                readonly
                                outlined
                                dense
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                              <v-combobox
                                v-model="currentData.selectedCabangTujuan"
                                label="Cabang Tujuan"
                                clearable
                                dense
                                outlined
                                :items="items_cabang"
                                item-text="name"
                                :rules="currentData.cabangTujuanRules"
                              ></v-combobox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogUpdate = false"
                      >
                        Close
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="doSave">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan menghapus data ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Tidak</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Ya</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogApprove" max-width="600px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Apa anda akan mengapprove iuran anggota
                      ini?</v-card-title
                    >
                    <v-card-actions>
                      <v-container>
                        <v-row>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeApprove"
                            >Tidak</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="approveItemConfirm"
                            >Ya</v-btn
                          >
                        </v-row>
                      </v-container>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogBuktiBayar" max-width="600px">
                  <v-card>
                    <v-img
                      height="auto"
                      width="auto"
                      :src="fotoBuktiBayar"
                    ></v-img>
                  </v-card>
                </v-dialog>
              </v-card-title>

              <v-row class="mx-6 mb-6">
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.nama"
                    append-icon="mdi-magnify"
                    label="Cari nama"
                    single-line
                    hide-details
                    @keyup.enter="getDataFromApi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="search.invoice_no"
                    append-icon="mdi-magnify"
                    label="No Invoice"
                    single-line
                    hide-details
                    @keyup.enter="getDataFromApi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-model="search.selectedKomisariat"
                    label="Komisariat"
                    clearable
                    dense
                    outlined
                    :items="items_komisariat"
                    item-text="nama"
                    @change="changeKomisariat"
                    :disabled="isAdminKomisariat"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-model="search.tahun"
                    label="Tahun"
                    clearable
                    dense
                    outlined
                    :items="items_tahun"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-model="search.selectedKategori"
                    label="Kategori"
                    clearable
                    dense
                    outlined
                    :items="items_kategori"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    v-model="search.selectedStatus"
                    label="Status"
                    clearable
                    dense
                    outlined
                    :items="items_status"
                    item-text="name"
                    @change="getDataFromApi"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1"
              >
                <template #[`item.kategori`]="{ item }">
                  <v-chip :color="getColorKategori(item.kategori)" dark>
                    {{ getKategori(item.kategori) }}
                  </v-chip>
                </template>
                <template #[`item.created_at`]="{ item }">
                  {{ getCurrentDate(item.created_at) }}
                </template>
                <template #[`item.status`]="{ item }">
                  <v-chip :color="getColor(item.status)" dark>
                    {{ getStatus(item.status) }}
                  </v-chip>
                </template>
                <template #[`item.foto_url`]="{ item }">
                  <v-btn
                    v-if="item.kategori == 4"
                    color="blue"
                    dark
                    class="mb-2"
                    @click="showFotoBuktiBayar(item.foto_url)"
                  >
                    View
                  </v-btn>
                </template>
                <template #[`item.nominal`]="{ item }">
                  {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
                </template>
                <template #[`item.nominal_pusat`]="{ item }">
                  <div v-if="item.nominal_pusat">
                    <div v-if="item.kategori == 1 || item.kategori == 3">
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          item.nominal_pusat,
                        )
                      }}
                    </div>
                  </div>
                </template>
                <template #[`item.nominal_cabang`]="{ item }">
                  <div v-if="item.nominal_cabang">
                    <div v-if="item.kategori == 1 || item.kategori == 3">
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          item.nominal_cabang,
                        )
                      }}
                    </div>
                  </div>
                </template>
                <template #[`item.virtual_account`]="{ item }">
                  <div v-if="item.virtual_account">
                    {{ item.virtual_account }}
                  </div>
                </template>
                <template #[`item.pusat_splitted`]="{ item }">
                  <div v-if="item.kategori == 1 || item.kategori == 3">
                    <v-chip :color="getColorSplitted(item.pusat_splitted)" dark>
                      {{ getSplittedType(item.pusat_splitted) }}
                    </v-chip>
                  </div>
                </template>
                <template #[`item.cabang_splitted`]="{ item }">
                  <div v-if="item.kategori == 1 || item.kategori == 3">
                    <v-chip
                      :color="getColorSplitted(item.cabang_splitted)"
                      dark
                    >
                      {{ getSplittedType(item.cabang_splitted) }}
                    </v-chip>
                  </div>
                </template>
                <template #[`item.actions`]="{ item }">
                  <div class="d-flex flex-row">
                    <v-icon
                      v-if="
                        item.kategori == 4 &&
                        (item.status == 2 ||
                          item.status == 3 ||
                          item.status == 7) &&
                        (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="approveItem(item)"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="
                        item.kategori == 4 &&
                        (item.status == 2 ||
                          item.status == 3 ||
                          item.status == 7) &&
                        (item.is_approver == 1 || getRoleId == 1)
                      "
                      medium
                      class="mr-3"
                      @click="rejectItem(item)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                  <!-- <v-icon
            medium
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item> <MasterIuranComponent /> </v-tab-item>
          <v-tab-item> <MasterRekeningComponent /> </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import IuranService from "../services/InvoiceService";
import CommonService from "../services/CommonService";
import KomisariatService from "../services/KomisariatService";
import * as moment from "moment";

import MasterIuranComponent from "./MasterIuran.vue";
import MasterRekeningComponent from "./MasterRekening.vue";
import store from "../store/index";

export default {
  name: "KeuanganAnggota",
  components: { MasterIuranComponent, MasterRekeningComponent },
  data: () => ({
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    dialogReject: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Keuangan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      invoice_no: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedKomisariat: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false, width: "210px" },
      {
        text: "Komisariat",
        value: "komisariat_nama",
        sortable: false,
        width: "120px",
      },
      { text: "NIRA", value: "nira", sortable: false },
      { text: "Invoice No", value: "invoice_no", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      {
        text: "No VA",
        value: "virtual_account",
        sortable: false,
        width: "100px",
      },
      { text: "Kategori", value: "kategori", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedCabang: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      nominal_pusat: 900000,
      nominal_cabang: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_cabang: [],
    items_tahun: [],
    items_komisariat: [],
    items_kategori: [
      {
        id: 1,
        name: "Pembayaran Iuran pendaftaran Anggota",
      },
      {
        id: 3,
        name: "Perpanjangan Iuran Anggota",
      },
      {
        id: 4,
        name: "Upload Data Iuran Lama",
      },
    ],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi Komisariat",
      },
      {
        id: 3,
        name: "Menunggu Konfirmasi DPD",
      },
      {
        id: 7,
        name: "Menunggu Konfirmasi DPW",
      },
      {
        id: 4,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        console.log("memanggil sekali");
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear() + 5; i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.search.tahun = new Date().getFullYear();

    this.getListKomisariat();
    this.getDataFromApi();
  },
  methods: {
    async getListKomisariat() {
      KomisariatService.komisariatListAll()
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.search.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeKomisariat() {
      this.getDataFromApi();
    },
    getSplittedType(id) {
      if (id == 1) {
        return "Sudah";
      } else {
        return "Belum";
      }
    },
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      } else if (id == 5) {
        return "Pembayaran Pelatihan";
      } else if (id == 6) {
        return "Update Tabsol Lama";
      }
    },

    getColorKategori(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "light-blue";
        case 2:
          return "green";
        case 3:
          return "cyan";
        case 4:
          return "blue";
        case 7:
          return "blue";
        default:
          return "red";
      }
    },

    getColorSplitted(dataStatus) {
      switch (dataStatus) {
        case 1:
          return "green";
        default:
          return "red";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Komisariat";
      } else if (id == 3) {
        return "Menunggu Konfirmasi DPD";
      } else if (id == 4) {
        return "Terverifikasi";
      } else if (id == 5) {
        return "Rejected";
      } else if (id == 6) {
        return "Canceled";
      } else if (id == 7) {
        return "Menunggu Konfirmasi DPW";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var komisariat = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedKomisariat) {
        komisariat = this.search.selectedKomisariat.id;
      }

      console.log("ambil invoice");

      IuranService.invoiceIuranList(
        page,
        itemsPerPage,
        this.search.nama,
        this.search.invoice_no,
        tahun,
        kategori,
        status,
        komisariat,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        IuranService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialogUpdate = false;
            CommonService.showSuccessToast("Update iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update iuran gagal. Coba lagi");
          });
      } else {
        IuranService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah iuran gagal. Coba lagi");
          });
      }
    },

    deleteItemConfirm() {
      this.closeDelete();

      IuranService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      IuranService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          CommonService.showSuccessToast("Approve iuran berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Approve iuran gagal. Coba lagi");
        });
    },

    async getMasterIuran() {
      if (
        this.currentData.selectedKategori &&
        this.currentData.selectedKategori.id != 2 &&
        this.currentData.selectedKategori.id != 6 &&
        this.currentData.nama &&
        this.currentData.tahun
      ) {
        IuranService.getMasterIuran(
          this.currentData.cabang_asal,
          this.currentData.tahun,
        )
          .then((res) => {
            var invoice = res.data.invoice;
            if (invoice) this.currentData.nominal_cabang = invoice.nominal;
            else this.currentData.nominal_cabang = 0;

            this.currentData.nominal =
              this.currentData.nominal_cabang + this.currentData.nominal_pusat;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.currentData.nominal = 0;
      }
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    rejectItemConfirm() {
      IuranService.reject(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          CommonService.showSuccessToast("Reject iuran berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Reject iuran gagal. Coba lagi");
        });
    },
  },
};
</script>
