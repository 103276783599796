<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Peserta Didik
          <v-spacer></v-spacer>

          <v-btn
            color="blue"
            dark
            class="mb-2 mr-2"
            @click="onDownloadFormUpload"
          >
            Form Upload</v-btn
          >
          <v-btn color="green" dark class="mb-2 mr-2" @click="openUploadForm">
            Upload Peserta Didik</v-btn
          >

          <router-link
            to="/nakes-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="npm"
              append-icon="mdi-magnify"
              label="Cari NPM"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field> </v-col
          ><v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedFakultas"
              label="Fakultas"
              clearable
              dense
              outlined
              :items="items_fakultas"
              item-text="name"
              :disabled="isAdminFakultas"
              @change="changeFakultas"
            ></v-combobox> </v-col
          ><v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedStase"
              label="Stase"
              clearable
              dense
              outlined
              :items="items_stase"
              item-text="name"
              @change="changeStase"
            ></v-combobox>
          </v-col>
          <!-- <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              @change="changeDpw"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedKomisariat"
              label="Komisariat"
              clearable
              dense
              outlined
              :items="items_komisariat"
              item-text="nama"
              :disabled="isAdminKomisariat"
              @change="changeKomisariat"
              @click="getListKomisariat"
            ></v-combobox>
          </v-col> -->
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          :single-select="true"
          show-select
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <MasaStaseComponent
          v-if="selected != undefined && selected.length > 0"
          :id="selected[0].id"
          ref="mychild"
          :key="childKey"
          :refresh="getDataFromApi"
        />
      </v-card>

      <v-dialog v-model="dialogUpload" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Upload Peserta Didik</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="pl-4 pr-4 pt-4"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="upload.selectedFakultas"
                      label="Fakultas"
                      clearable
                      dense
                      outlined
                      :items="items_fakultas"
                      item-text="name"
                      :rules="upload.fakultasRules"
                      @change="changeFakultasUpload"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="upload.selectedStase"
                      label="Stase"
                      clearable
                      dense
                      outlined
                      :items="items_stase_per_fakultas"
                      item-text="name"
                      :rules="upload.staseRules"
                      @click="getListStaseByFakultas"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="upload.tanggal_awal"
                      label="Awal Stase"
                      type="date"
                      dense
                      :rules="upload.tanggalAwalRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="upload.tanggal_akhir"
                      label="Selesai Stase"
                      type="date"
                      dense
                      :rules="upload.tanggalAkhirRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                      v-model="upload.file_upload"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      show-size
                      label="File Upload"
                      :rules="upload.fileUploadRules"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogUpload = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="doUploadForm">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import StaseService from "../services/StaseService";
import FakultasService from "../services/FakultasService";
import MasaStaseComponent from "./MasaStase.vue";
import CommonService from "../services/CommonService";
// import KomisariatService from "../services/KomisariatService";
import store from "../store/index";
// import DPWService from "../services/DPWService";
// import DPDService from "../services/DPDService";

export default {
  components: {
    MasaStaseComponent,
  },
  data: () => ({
    isProgressBarShow: false,
    valid: false,
    breadcrumbs: [
      {
        text: "Data Peserta Didik",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    childKey: 0,
    search: "",
    npm: "",
    selected: [],
    selectedDpw: "",
    selectedDpd: "",
    selectedKomisariat: "",
    selectedStase: "",
    selectedFakultas: "",
    // items_komisariat: [],
    // items_dpw: [],
    // items_dpd: [],
    items_stase: [],
    items_stase_per_fakultas: [],
    items_fakultas: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "NPM", value: "npm", sortable: false },
      { text: "Fakultas", value: "fakultas_nama", sortable: false },
      { text: "Stase", value: "stase_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      // { text: "DPW", value: "dpw_nama", sortable: false },
      // { text: "DPD", value: "dpd_nama", sortable: false },
      // { text: "Komisariat", value: "komisariat_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
    dialogUpload: false,
    upload: {
      selectedFakultas: "",
      selectedStase: "",
      file_upload: null,
      fakultasRules: [(v) => !!v || "Fakultas harus diisi"],
      staseRules: [(v) => !!v || "Stase harus diisi"],
      fileUploadRules: [(v) => !!v || "File Upload harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Awal Stase harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Selesai Stase harus diisi"],
    },
  }),
  computed: {
    getFakultasId() {
      return store.getters.getUser.fakultas_id;
    },
    isAdminFakultas() {
      if (store.getters.getUser.fakultas_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListDpw();
    this.getListFakultas();
    this.getListStase();
    this.getDataFromApi();
  },
  methods: {
    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;

          if (this.getFakultasId) {
            this.items_fakultas.forEach((entry) => {
              if (entry.id == this.getFakultasId) {
                this.selectedFakultas = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStase() {
      StaseService.staseAll()
        .then((res) => {
          this.items_stase = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeFakultasUpload() {
      this.upload.selectedStase = null;
    },

    async getListStaseByFakultas() {
      StaseService.staseAllByFakultas(this.upload.selectedFakultas.id)
        .then((res) => {
          this.items_stase_per_fakultas = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeStase() {
      this.getDataFromApi();
    },
    changeFakultas() {
      this.getDataFromApi();
    },
    // changeDpw() {
    //   this.selectedDpd = null;
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    // changeDpd() {
    //   this.selectedKomisariat = null;
    //   this.getDataFromApi();
    // },

    // async getListDpw() {
    //   DPWService.dpwListAll()
    //     .then((res) => {
    //       this.items_dpw = res.data.list_data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // async getListDpd() {
    //   DPDService.dpdListAll(this.selectedDpw.id)
    //     .then((res) => {
    //       this.items_dpd = res.data.list_data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // async getListKomisariat() {
    //   KomisariatService.komisariatListAllFilter(this.selectedDpd.id)
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.selectedKomisariat) {
        komisariat = this.selectedKomisariat.id;
      }

      var dpw = "";
      var dpd = "";
      var stase = "";
      var fakultas = "";

      if (this.selectedDpw) {
        dpw = this.selectedDpw.id;
      }

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      if (this.selectedFakultas) {
        fakultas = this.selectedFakultas.id;
      }

      if (this.selectedStase) {
        stase = this.selectedStase.id;
      }

      this.loading = true;
      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        komisariat,
        dpw,
        dpd,
        this.npm,
        stase,
        fakultas,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus Peserta Didik berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus Peserta Didik gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onDownloadFormUpload() {
      this.isProgressBarShow = true;

      NakesService.downloadFormUpload()
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download form. Coba lagi");
          console.log(err);
        });
    },

    openUploadForm() {
      this.upload.selectedFakultas = "";
      this.upload.selectedStase = "";
      this.upload.file_upload = null;
      this.dialogUpload = true;
    },

    doUploadForm() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.uploadPesertaDidik(this.upload)
        .then((res) => {
          console.log(res);
          this.dialogUpload = false;
          CommonService.showSuccessToast("Upload Peserta Didik berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Upload Peserta Didik gagal. Coba lagi");
        });
    },
  },
};
</script>
