<template>
  <div>
    <v-card>
      <v-card-title>
        Masa Stase <br />

        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Kategori"
          single-line
          hide-details
          @keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer> -->

        <v-btn color="purple" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Masa Stase</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        v-model="currentData.selectedStase"
                        label="Stase"
                        clearable
                        dense
                        outlined
                        :items="items_stase"
                        item-text="name"
                        :rules="currentData.staseRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.tanggal_awal"
                        label="Awal Stase"
                        type="date"
                        dense
                        :rules="currentData.tanggalAwalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.tanggal_akhir"
                        label="Selesai Stase"
                        type="date"
                        dense
                        :rules="currentData.tanggalAkhirRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.tanggal_awal`]="{ item }">
          {{ getCurrentDate(item.tanggal_awal) }}
        </template>
        <template #[`item.tanggal_akhir`]="{ item }">
          {{ getCurrentDate(item.tanggal_akhir) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import MasaStaseService from "../services/MasaStaseService";
import CommonService from "../services/CommonService";
import StaseService from "../services/StaseService";
import * as moment from "moment";

export default {
  components: {},
  props: {
    id: {
      type: Number,
      default: 0,
    },
    refresh: Function,
  },
  data: () => ({
    childKey: 0,
    isEditable: 0,
    valid: true,
    search: "",
    selected: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Stase", value: "stase_name", sortable: false },
      { text: "Awal Stase", value: "tanggal_awal", sortable: false },
      { text: "Selesai Stase", value: "tanggal_akhir", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_stase: [],

    currentData: {
      pelatihan_id: "",
      id: null,
      user_id: "",
      title: "",
      urutan: "",
      selectedStase: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      staseRules: [(v) => !!v || "Stase harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Awal Stase harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Akhir Stase harus diisi"],
    },
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListStase();
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    async getListStase() {
      StaseService.staseAll()
        .then((res) => {
          this.items_stase = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      MasaStaseService.masaStaseList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      // this.currentData = this.editedItem;

      this.currentData.id = this.editedItem.id;
      this.currentData.user_id = this.editedItem.user_id;

      if (this.editedItem.stase_id) {
        this.items_stase.forEach((entry) => {
          if (entry.id == this.editedItem.stase_id) {
            this.currentData.selectedStase = entry;
          }
        });
      }

      if (this.editedItem.tanggal_awal) {
        this.currentData.tanggal_awal = moment(
          this.editedItem.tanggal_awal,
        ).format("YYYY-MM-DD");
      }

      if (this.editedItem.tanggal_akhir) {
        this.currentData.tanggal_akhir = moment(
          this.editedItem.tanggal_akhir,
        ).format("YYYY-MM-DD");
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.user_id = this.id;
        MasaStaseService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Masa Stase berhasil");

            this.getDataFromApi();
            this.refresh();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Tambah Masa Stase gagal. Coba lagi");
          });
      } else {
        this.currentData.user_id = this.id;

        MasaStaseService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update Masa Stase berhasil");

            this.getDataFromApi();
            this.refresh();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showErrorToast("Update Masa Stase gagal. Coba lagi");
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MasaStaseService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Masa Stase berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Masa Stase gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
