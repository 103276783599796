<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Admin Fakultas
          <v-spacer />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer /> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Admin Fakultas</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="currentData.selectedFakultas"
                        label="Fakultas"
                        clearable
                        dense
                        outlined
                        :items="items_fakultas"
                        item-text="name"
                        :rules="currentData.fakultasRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.email"
                        label="Email"
                        required
                        :rules="currentData.emailRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedFakultas"
              label="Fakultas"
              clearable
              dense
              outlined
              :items="items_fakultas"
              item-text="name"
              @change="changeFakultas"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import AdminFakultasService from "../services/AdminFakultasService";
import FakultasService from "../services/FakultasService";
import CommonService from "../services/CommonService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Admin",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedFakultas: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Fakultas", value: "fakultas_name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_fakultas: [],
    currentData: {
      id: null,
      nama: "",
      email: "",
      selectedFakultas: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      fakultasRules: [(v) => !!v || "Fakultas harus diisi"],
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListFakultas();
    this.getDataFromApi();
  },
  methods: {
    changeFakultas() {
      this.getDataFromApi();
    },
    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var fakultas = "";

      if (this.selectedFakultas) {
        fakultas = this.selectedFakultas.id;
      }

      this.loading = true;
      AdminFakultasService.adminList(page, itemsPerPage, this.search, fakultas)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.nama = "";
      this.currentData.email = "";
      this.currentData.selectedFakultas = "";
      this.currentData.id = null;
      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id == null) {
        AdminFakultasService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Admin Fakultas berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            if (err.response?.data?.code == 1) {
              CommonService.showErrorToast(err.response?.data?.message);
            } else {
              CommonService.showErrorToast(
                "Tambah Admin Fakultas gagal. Coba lagi",
              );
            }
          });
      } else {
        AdminFakultasService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Admin Fakultas berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            if (err.response?.data?.code == 1) {
              CommonService.showErrorToast(err.response?.data?.message);
            } else {
              CommonService.showErrorToast(
                "Update Admin Fakultas gagal. Coba lagi",
              );
            }
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.email = this.editedItem.email;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.fakultas_id) {
        this.items_fakultas.forEach((entry) => {
          if (entry.id == this.editedItem.fakultas_id) {
            this.currentData.selectedFakultas = entry;
          }
        });
      }

      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      AdminFakultasService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Admin Fakultas berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Admin Fakultas gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
