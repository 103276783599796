<template>
  <div>
    <v-card>
      <v-card-title>
        Master Rekening
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="1000px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Master Rekening</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row>
                  <v-col cols="6">
                    <v-container>
                      <v-row
                        ><v-col cols="12">
                          <v-combobox
                            v-model="currentData.selectedKomisariat"
                            label="Komisariat"
                            clearable
                            dense
                            outlined
                            :items="items_komisariat"
                            item-text="nama"
                            :rules="currentData.komisariatRules"
                            :disabled="isAdminKomisariat"
                          ></v-combobox> </v-col
                      ></v-row>
                      <v-row
                        ><v-col cols="12">
                          <v-combobox
                            v-model="currentData.selectedBank"
                            label="Bank"
                            clearable
                            dense
                            outlined
                            :items="items_bank"
                            item-text="name"
                            :rules="currentData.bankRules"
                          ></v-combobox> </v-col
                      ></v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="currentData.nama_rekening"
                            label="Nama Rekening Bank"
                            required
                            outlined
                            dense
                            :rules="currentData.namaRekeningRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="currentData.no_rekening"
                            label="No Rekening Bank"
                            required
                            outlined
                            dense
                            :rules="currentData.noRekeningRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="currentData.virtual_account"
                            label="Virtual Akun IPaymu"
                            required
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="currentData.telp"
                            label="No Handphone"
                            required
                            outlined
                            dense
                            :rules="currentData.phoneRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="currentData.email"
                            label="Email"
                            required
                            outlined
                            dense
                            :rules="currentData.emailRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <v-container>
                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="currentData.user_tanggal_lahir"
                            label="Tanggal Lahir"
                            type="date"
                            outlined
                            dense
                            :rules="currentData.tanggalLahirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="currentData.user_tempat_lahir"
                            label="Tempat Lahir"
                            outlined
                            dense
                            :rules="currentData.tempatLahirRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        ><v-col cols="12">
                          <v-combobox
                            v-model="currentData.selectedJenisKelamin"
                            label="Jenis Kelamin"
                            clearable
                            outlined
                            dense
                            :items="items_jenis_kelamin"
                            item-text="name"
                            :rules="currentData.jenisKelaminRules"
                          ></v-combobox>
                        </v-col>
                      </v-row>

                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="currentData.user_no_ktp"
                            label="No KTP"
                            outlined
                            dense
                            :rules="currentData.noKtpRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="currentData.user_npwp"
                            label="No NPWP"
                            outlined
                            dense
                            :rules="currentData.noNpwpRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        ><v-col cols="12">
                          <v-text-field
                            v-model="currentData.user_kode_pos"
                            label="Kode POS"
                            outlined
                            dense
                            :rules="currentData.kodePosRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
                <v-row
                  ><v-col cols="12">
                    <v-text-field
                      v-model="currentData.user_alamat"
                      label="Alamat Lengkap"
                      outlined
                      dense
                      :rules="currentData.addressRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6"
                    ><v-combobox
                      v-model="currentData.selectedPropinsi"
                      label="Propinsi"
                      clearable
                      dense
                      outlined
                      :items="items_propinsi"
                      item-text="name"
                      :rules="currentData.propinsiRules"
                      @change="changePropinsi"
                      @click="getListPropinsiIPaymu"
                    ></v-combobox
                  ></v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="currentData.selectedKabupaten"
                      label="Kabupaten/Kota"
                      clearable
                      dense
                      outlined
                      :items="items_kabupaten"
                      item-text="name"
                      :rules="currentData.kabupatenRules"
                      @click="getListKabupatenIPaymu"
                      @change="changeKabupaten"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="currentData.selectedKecamatan"
                      label="Kecamatan"
                      clearable
                      dense
                      outlined
                      :items="items_kecamatan"
                      item-text="name"
                      :rules="currentData.kecamatanRules"
                      @click="getListKecamatanIPaymu"
                      @change="changeKecamatan"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="currentData.selectedKelurahan"
                      label="Kelurahan"
                      clearable
                      dense
                      outlined
                      :items="items_kelurahan"
                      item-text="name"
                      :rules="currentData.kelurahanRules"
                      @click="getListKelurahanIPaymu"
                    ></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="currentData.foto_tabungan"
                          accept="image/png, image/jpeg, image/jpg"
                          prepend-icon="mdi-image"
                          show-size
                          label="Foto Buku Tabungan"
                          outlined
                          dense
                          @change="loadImage"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-responsive
                          v-if="currentData.FotoUrl"
                          :aspect-ratio="16 / 9"
                        >
                          <v-img
                            :src="currentData.FotoUrl"
                            :lazy-src="currentData.FotoUrl"
                            contain
                          >
                          </v-img>
                        </v-responsive>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="currentData.user_ktp"
                          accept="image/png, image/jpeg, image/jpg"
                          prepend-icon="mdi-image"
                          show-size
                          label="Foto KTP"
                          outlined
                          dense
                          @change="loadImageKtp"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-responsive
                          v-if="currentData.FotoKtpUrl"
                          :aspect-ratio="16 / 9"
                        >
                          <v-img
                            :key="currentData.fotoKtpKey"
                            :src="currentData.FotoKtpUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          v-model="currentData.user_selfie"
                          accept="image/png, image/jpeg, image/jpg"
                          prepend-icon="mdi-image"
                          show-size
                          label="Foto Selfie dengan KTP"
                          outlined
                          dense
                          @change="loadImageSelfie"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-responsive
                          v-if="currentData.FotoSelfieUrl"
                          :aspect-ratio="16 / 9"
                        >
                          <v-img
                            :key="currentData.fotoSelfieKey"
                            :src="currentData.FotoSelfieUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            v-model="search.selectedKomisariat"
            label="Komisariat"
            clearable
            dense
            outlined
            :items="items_komisariat"
            item-text="nama"
            @change="changeKomisariat"
            :disabled="isAdminKomisariat"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template #[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import MasterRekeningService from "../services/MasterRekeningService";
import CommonService from "../services/CommonService";
import NakesService from "../services/NakesService";
import KomisariatService from "../services/KomisariatService";
import * as moment from "moment";
import store from "../store/index";

export default {
  data: () => ({
    valid: true,
    search: {
      selectedKomisariat: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Pengurus", value: "komisariat_nama", sortable: false },
      { text: "Bank", value: "bank_name", sortable: false },
      { text: "Nama Rekening", value: "nama_rekening", sortable: false },
      { text: "No Rekening", value: "no_rekening", sortable: false },
      {
        text: "Virtual Akun IPaymu",
        value: "virtual_account",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      no_rekening: "",
      nama_rekening: "",
      virtual_account: "",
      selectedBank: "",
      urutan: "",
      telp: "",
      fax: "",
      email: "",
      alamat: "",
      website: "",
      latitude: "",
      longitude: "",
      foto_tabungan: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      fotoKtpKey: 0,
      FotoKtpUrl: "",
      fotoSelfieKey: 0,
      FotoSelfieUrl: "",
      user_tanggal_lahir: "",
      user_tempat_lahir: "",
      selectedGender: "",
      user_no_ktp: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      user_kode_pos: "",
      user_alamat: "",
      user_npwp: "",
      user_ktp: null,
      user_selfie: null,
      selectedKomisariat: "",
      bankRules: [(v) => !!v || "Bank harus diisi"],
      namaRekeningRules: [(v) => !!v || "Nama Rekening Bank harus diisi"],
      noRekeningRules: [(v) => !!v || "No Rekening Bank harus diisi"],
      phoneRules: [(v) => !!v || "No Handphone harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      tanggalLahirRules: [(v) => !!v || "Tanggal Lahir harus diisi"],
      tempatLahirRules: [(v) => !!v || "Tempat Lahir harus diisi"],
      jenisKelaminRules: [(v) => !!v || "Jenis Kelamin harus diisi"],
      noKtpRules: [(v) => !!v || "No KTP harus diisi"],
      noNpwpRules: [(v) => !!v || "No NPWP harus diisi"],
      kodePosRules: [(v) => !!v || "Kode Pos harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kota/Kabupaten harus diisi"],
      kecamatanRules: [(v) => !!v || "Kecamatan harus diisi"],
      kelurahanRules: [(v) => !!v || "Kelurahan harus diisi"],
      komisariatRules: [(v) => !!v || "Komisariat harus diisi"],
      addressRules: [
        (v) => !!v || "Alamat harus diisi",
        (v) => (v && v.length <= 200) || "Maksimal alamat 200 karakter",
      ],
    },
    items_cabang: [],
    items_bank: [],
    items_jenis_kelamin: [
      {
        code: "L",
        name: "Laki-Laki",
      },
      {
        code: "P",
        name: "Perempuan",
      },
    ],
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_komisariat: [],
  }),

  computed: {
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    isAdminKomisariat() {
      if (store.getters.getUser.komisariat_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListPropinsi();
    this.getListPropinsiIPaymu();
    this.getListMasterBank();
    // this.getListJenisKelamin();
    this.getListKomisariat();
    this.getDataFromApi();
  },
  methods: {
    async getListKomisariat() {
      KomisariatService.komisariatListAll()
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.search.selectedKomisariat = entry;
                this.currentData.selectedKomisariat = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeKomisariat() {
      this.getDataFromApi();
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoUrl = e.target.result;
            this.currentData.fotoKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_tabungan = null;
      }
    },

    loadImageKtp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoKtpUrl = e.target.result;
            this.currentData.fotoKtpKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoKtpUrl = "";
        this.currentData.user_ktp = null;
      }
    },

    loadImageSelfie: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$nextTick(() => {
            this.currentData.FotoSelfieUrl = e.target.result;
            this.currentData.fotoSelfieKey++;
          });
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoSelfieUrl = "";
        this.currentData.user_selfie = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var komisariat = "";

      if (this.search.selectedKomisariat) {
        komisariat = this.search.selectedKomisariat.id;
      }

      this.loading = true;
      MasterRekeningService.masterRekeningList(page, itemsPerPage, komisariat)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.FotoUrl = "";

      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      // this.currentData.selectedPropinsi = "";
      this.isCabangDisabled = false;

      if (!this.getKomisariatId) {
        this.currentData.selectedKomisariat = null;
      }

      console.log("do add");
    },

    doSave: async function () {
      console.log(1);
      if (!this.$refs.form.validate()) {
        return;
      }

      console.log(2);

      if (
        !this.editedItem.foto_tabungan_original &&
        !this.currentData.foto_tabungan
      ) {
        CommonService.showErrorToast("Foto Buku Tabungan harus diisi");
        return;
      }

      console.log(3);
      if (!this.editedItem.user_ktp_original && !this.currentData.user_ktp) {
        CommonService.showErrorToast("Foto KTP harus diisi");
        return;
      }

      console.log(4);
      if (
        !this.editedItem.user_selfie_original &&
        !this.currentData.user_selfie
      ) {
        CommonService.showErrorToast("Foto Selfie dengan KTP harus diisi");
        return;
      }

      console.log(5);
      //check ukuran foto buku tabungan
      if (
        this.currentData.foto_tabungan &&
        this.currentData.foto_tabungan.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast("Maksimal ukuran foto buku tabungan 2MB");
        return;
      }

      console.log(6);
      if (
        this.currentData.user_ktp &&
        this.currentData.user_ktp.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast("Maksimal ukuran foto KTP 2MB");
        return;
      }

      console.log(7);
      if (
        this.currentData.user_selfie &&
        this.currentData.user_selfie.size > 2 * 1024 * 1024
      ) {
        CommonService.showErrorToast(
          "Maksimal ukuran foto Selfie dengan KTP 2MB",
        );
        return;
      }

      console.log(8);
      if (this.currentData.id != null) {
        MasterRekeningService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update Master Rekening berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update Master Rekening gagal. Coba lagi",
            );
          });
      } else {
        MasterRekeningService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah Master Rekening berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah Master Rekening gagal. Coba lagi",
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.no_rekening = this.editedItem.no_rekening;
      this.currentData.nama_rekening = this.editedItem.nama_rekening;
      this.currentData.virtual_account = this.editedItem.virtual_account;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.telp = this.editedItem.telp;
      this.currentData.email = this.editedItem.email;

      if (this.editedItem.foto_tabungan_original) {
        this.currentData.fotoKey++;
        this.$nextTick(() => {
          this.currentData.FotoUrl = this.editedItem.foto_tabungan;
        });
      } else {
        this.currentData.FotoUrl = null;
      }

      if (this.editedItem.user_ktp_original)
        this.currentData.FotoKtpUrl = this.editedItem.user_ktp;
      else this.currentData.FotoKtpUrl = null;

      if (this.editedItem.user_selfie_original)
        this.currentData.FotoSelfieUrl = this.editedItem.user_selfie;
      else this.currentData.FotoSelfieUrl = null;

      this.currentData.id = this.editedItem.id;
      this.currentData.user_tempat_lahir = this.editedItem.user_tempat_lahir;
      this.currentData.user_no_ktp = this.editedItem.user_no_ktp;
      this.currentData.user_kode_pos = this.editedItem.user_kode_pos;
      this.currentData.user_alamat = this.editedItem.user_alamat;
      this.currentData.user_npwp = this.editedItem.user_npwp;

      if (this.editedItem.user_tanggal_lahir) {
        this.currentData.user_tanggal_lahir = moment(
          this.editedItem.user_tanggal_lahir,
        ).format("YYYY-MM-DD");
      }

      this.currentData.selectedBank = null;

      if (this.editedItem.bank_code) {
        this.$nextTick(() => {
          this.currentData.selectedBank = {
            id: this.editedItem.bank_code,
            name: this.editedItem.bank_name,
          };
        });
      }

      this.currentData.selectedJenisKelamin = null;

      this.items_jenis_kelamin.forEach((entry) => {
        if (entry.code == this.editedItem.user_gender) {
          this.currentData.selectedJenisKelamin = entry;
        }
      });

      this.items_komisariat.forEach((entry) => {
        if (entry.id == this.editedItem.komisariat_id) {
          this.currentData.selectedKomisariat = entry;
        }
      });

      if (this.editedItem.user_propinsi) {
        this.currentData.selectedPropinsi = {
          code: this.editedItem.user_propinsi,
          name: this.editedItem.user_propinsi_name,
        };
      }

      if (this.editedItem.user_kabupaten) {
        this.currentData.selectedKabupaten = {
          code: this.editedItem.user_kabupaten,
          name: this.editedItem.user_kabupaten_name,
        };
      }

      if (this.editedItem.user_kecamatan) {
        this.currentData.selectedKecamatan = {
          code: this.editedItem.user_kecamatan,
          name: this.editedItem.user_kecamatan_name,
        };
      }

      if (this.editedItem.user_kelurahan) {
        this.currentData.selectedKelurahan = {
          code: this.editedItem.user_kelurahan,
          name: this.editedItem.user_kelurahan_name,
        };
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MasterRekeningService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Master Rekening berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast(
            "Hapus Master Rekening gagal. Coba lagi",
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getListMasterBank() {
      MasterRekeningService.masterBankList()
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_bank = res.data.resp.Data.bank;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsiIPaymu() {
      MasterRekeningService.propinsiListIpaymu()
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_propinsi = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenIPaymu() {
      console.log(this.currentData.selectedPropinsi);
      MasterRekeningService.kabupatenListIpaymu(
        this.currentData.selectedPropinsi.code,
      )
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kabupaten = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanIPaymu() {
      MasterRekeningService.kecamatanListIpaymu(
        this.currentData.selectedKabupaten.code,
      )
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kecamatan = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanIPaymu() {
      MasterRekeningService.kelurahanListIpaymu(
        this.currentData.selectedKecamatan.code,
      )
        .then((res) => {
          if (res.data.resp.Status == 200) {
            this.items_kelurahan = res.data.resp.Data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.currentData.selectedKabupaten = null;
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },

    async changeKabupaten() {
      this.currentData.selectedKecamatan = null;
      this.currentData.selectedKelurahan = null;
    },
    async changeKecamatan() {
      this.currentData.selectedKelurahan = null;
    },
  },
};
</script>
