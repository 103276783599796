import http from "../http-common";
/* eslint-disable */
class AdminFakultasService {
  adminList(page, itemPerPage, keyword, fakultas) {
    return http.get(
      "/admin-fakultas/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&fakultas_id=" +
        fakultas,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  staseAll() {
    return http.get("/admin-fakultas/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  staseAllByFakultas(fakultasId) {
    return http.get(
      `/admin-fakultas/all-by-fakultas?fakultas_id=${fakultasId}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);

    if (data.selectedFakultas && data.selectedFakultas != undefined) {
      bodyFormData.append("fakultas_id", data.selectedFakultas.id);
    }

    return http.post("/admin-fakultas", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);

    if (data.selectedFakultas && data.selectedFakultas != undefined) {
      bodyFormData.append("fakultas_id", data.selectedFakultas.id);
    }

    return http.put(`/admin-fakultas/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/admin-fakultas/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AdminFakultasService();
