<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Peserta Didik </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img :key="fotoKey" :src="userData.FotoUrl" contain></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="userData.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <!-- <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.nira"
                    label="NIRA"
                    outlined
                    dense
                    :rules="userData.niraRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-combobox
                    v-model="userData.selectedKomisariat"
                    label="Komisariat"
                    clearable
                    dense
                    outlined
                    :items="items_komisariat"
                    item-text="nama"
                    :disabled="isAdminKomisariat"
                  ></v-combobox>
                </v-col>
              </v-row> -->
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.nama"
                    label="Nama"
                    outlined
                    dense
                    required
                    :rules="userData.namaRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.npm"
                    label="NPM"
                    outlined
                    dense
                    :rules="userData.npmRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-combobox
                    v-model="userData.selectedFakultas"
                    label="Fakultas"
                    clearable
                    dense
                    outlined
                    :items="items_fakultas"
                    item-text="name"
                    :rules="userData.fakultasRules"
                    :disabled="isAdminFakultas"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.phone"
                    label="No Telepon"
                    outlined
                    dense
                    :rules="userData.phoneRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.email"
                    label="Email"
                    outlined
                    dense
                    :rules="userData.emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.str"
                    label="STR"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.masa_berlaku_str"
                    label="Masa Berlaku STR"
                    dense
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Personal Information</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedJenisKelamin"
                    label="Jenis Kelamin"
                    clearable
                    dense
                    outlined
                    :items="items_jenis_kelamin"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedAgama"
                    label="Agama"
                    clearable
                    dense
                    outlined
                    :items="items_agama"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.no_ktp"
                    label="No KTP"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.tempat_lahir"
                    label="Tempat Lahir"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="userData.tanggal_lahir"
                    label="Tanggal Lahir"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedPropinsi"
                    label="Propinsi"
                    clearable
                    dense
                    outlined
                    :items="items_propinsi"
                    item-text="name"
                    @change="changePropinsi"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedKabupaten"
                    label="Kabupaten/Kota"
                    clearable
                    dense
                    outlined
                    :items="items_kabupaten"
                    item-text="name"
                    @click="getListKabupaten"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedKecamatan"
                    label="Kecamatan"
                    clearable
                    dense
                    outlined
                    :items="items_kecamatan"
                    item-text="name"
                    @click="getListKecamatan"
                    @change="changeKecamatan"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-combobox
                    v-model="userData.selectedKelurahan"
                    label="Kelurahan"
                    clearable
                    dense
                    outlined
                    :items="items_kelurahan"
                    item-text="name"
                    @click="getListKelurahan"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="userData.address"
                    label="Alamat Lengkap"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn style="margin-right: 10px" @click="doBatal">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <!-- Modal -->
      <v-dialog v-model="dialogFaskes" max-width="600px">
        <v-card>
          <v-card-title>
            Faskes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchNakes"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              hide-details
              @keyup.enter="getNakesFromApi"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ma-2"
                color="primary"
                dark
                @click="pilihFaskes(item)"
              >
                Pilih
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogFaskes = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import FaskesService from "../services/FaskesService";
import CommonService from "../services/CommonService";
import store from "../store/index";
import KomisariatService from "../services/KomisariatService";
import StaseService from "../services/StaseService";
import FakultasService from "../services/FakultasService";

export default {
  name: "NakesAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Peserta Didik",
        disabled: false,
        exact: true,
        to: { name: "Nakes" },
      },
      { text: "Tambah Peserta Didik", disabled: true },
    ],
    valid: true,
    userData: {
      FotoUrl: "",
      file_foto: null,
      nira: "",
      nama: "",
      npm: "",
      email: "",
      phone: "",
      str: "",
      no_ktp: "",
      tempat_lahir: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      selectedKomisariat: "",
      selectedStase: "",
      selectedFakultas: "",
      tanggal_lahir: "",
      address: "",
      masa_berlaku: "",
      selectedJenisKelamin: "",
      masa_berlaku_str: "",
      selectedAgama: "",
      niraRules: [(v) => !!v || "NIRA harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "Email harus diisi"],
      npmRules: [(v) => !!v || "NPM harus diisi"],
      staseRules: [(v) => !!v || "Stase harus diisi"],
      fakultasRules: [(v) => !!v || "Fakultas harus diisi"],
    },
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_jenis_kelamin: [],
    items_agama: [],
    items_komisariat: [],
    items_stase: [],
    items_fakultas: [],
    fotoKey: 0,
    currentFaskes: {
      id: "",
      nama: "",
    },
    dialogFaskes: false,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  computed: {
    getKomisariatId() {
      return store.getters.getUser.komisariat_id;
    },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    getFakultasId() {
      return store.getters.getUser.fakultas_id;
    },
    isAdminFakultas() {
      if (store.getters.getUser.fakultas_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListKecamatan();
    this.getListKelurahan();
    this.getListJenisKelamin();
    this.getListAgama();
    this.getListKomisariat();
    this.getListStase();
    this.getListFakultas();

    this.userData.masa_berlaku_str = "SELAMANYA";
  },
  methods: {
    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;

          if (this.getFakultasId) {
            this.items_fakultas.forEach((entry) => {
              if (entry.id == this.getFakultasId) {
                this.userData.selectedFakultas = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStase() {
      StaseService.staseAll()
        .then((res) => {
          this.items_stase = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKomisariat() {
      KomisariatService.komisariatListAll()
        .then((res) => {
          this.items_komisariat = res.data.list_data;

          if (this.getKomisariatId) {
            this.items_komisariat.forEach((entry) => {
              if (entry.id == this.getKomisariatId) {
                this.userData.selectedKomisariat = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pilihFaskes: function (item) {
      console.log(item);
      this.currentFaskes.id = item.id;
      this.currentFaskes.nama = item.nama;
      this.userData.faskes_id = item.id;

      this.dialogFaskes = false;
    },

    getNakesFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesService.faskesList(page, itemsPerPage, this.searchNakes)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    async getListJenisKelamin() {
      NakesService.jenisKelaminList()
        .then((res) => {
          this.items_jenis_kelamin = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListAgama() {
      NakesService.agamaList()
        .then((res) => {
          this.items_agama = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
      this.userData.selectedKecamatan = null;
      this.userData.selectedKelurahan = null;
    },

    async changeKecamatan() {
      this.userData.selectedKelurahan = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.userData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.userData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.create(this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Peserta Didik berhasil");

          this.$router.push("/nakes-edit/" + res.data.user.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Peserta Didik gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
