<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" />

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Cuti Absen
          <v-spacer />
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          />
          <v-spacer /> -->

          <v-dialog v-model="dialog" persistent max-width="600px">
            <!-- <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">Admin Fakultas</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-combobox
                        v-model="currentData.selectedFakultas"
                        label="Fakultas"
                        clearable
                        dense
                        outlined
                        :items="items_fakultas"
                        item-text="name"
                        :rules="currentData.fakultasRules"
                        :disabled="isAdminFakultas"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                        :rules="currentData.namaRules"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.email"
                        label="Email"
                        required
                        :rules="currentData.emailRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Apa anda akan menghapus data ini?
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Tidak
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  Ya
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedFakultas"
              label="Fakultas"
              clearable
              dense
              outlined
              :items="items_fakultas"
              item-text="name"
              :disabled="isAdminFakultas"
              @change="changeFakultas"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.tanggal_awal`]="{ item }">
            <span>{{ getCurrentDate(item.tanggal_awal) }}</span>
          </template>
          <template #[`item.tanggal_akhir`]="{ item }">
            <span>{{ getCurrentDate(item.tanggal_akhir) }}</span>
          </template>
          <template #[`item.tipe`]="{ item }">
            <span v-if="item.tipe == 1">Cuti Tahunan</span>
            <span v-if="item.tipe == 2">Sakit</span>
          </template>
          <template #[`item.status`]="{ item }">
            <span v-if="item.status == 1">Menunggu Approval</span>
            <span v-if="item.status == 2">Disetujui</span>
            <span v-if="item.status == 3">Ditolak</span>
          </template>
          <template #[`item.photo`]="{ item }">
            <v-btn
              v-if="item.photo_original"
              color="blue"
              dark
              class="mb-2"
              @click="showFoto(item.photo)"
            >
              View
            </v-btn>
          </template>
          <template #[`item.actions`]="{ item }">
            <!-- <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon> -->
            <div v-if="item.status == 1" class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="approveItem(item)">
                mdi-check
              </v-icon>
              <v-icon medium class="mr-3" @click="rejectItem(item)">
                mdi-close
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="dialogApprove" max-width="600px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan mengapprove cuti peserta ini?</v-card-title
          >
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeApprove"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="approveItemConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogReject" max-width="700px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan mereject cuti ini?</v-card-title
          >
          <v-card-actions>
            <v-container>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeReject"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="rejectItemConfirm"
                  >Ya</v-btn
                >
              </v-row>
            </v-container>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPhoto" max-width="600px">
        <v-card>
          <v-img height="auto" width="auto" :src="fotoAbsen"></v-img>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import CutiService from "../services/CutiService";
import FakultasService from "../services/FakultasService";
import CommonService from "../services/CommonService";
import * as moment from "moment";
import store from "../store/index";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Cuti",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedFakultas: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "user_nama", sortable: false },
      { text: "Fakultas", value: "fakultas_nama", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Tipe", value: "tipe", sortable: false },
      { text: "Alasan", value: "alasan", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Foto", value: "photo", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_fakultas: [],
    currentData: {
      id: null,
      nama: "",
      email: "",
      selectedFakultas: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      fakultasRules: [(v) => !!v || "Fakultas harus diisi"],
    },
    dialogApprove: false,
    dialogReject: false,
    dialogPhoto: false,
    fotoAbsen: null,
  }),
  computed: {
    getFakultasId() {
      return store.getters.getUser.fakultas_id;
    },
    isAdminFakultas() {
      if (store.getters.getUser.fakultas_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListFakultas();
    this.getDataFromApi();
  },
  methods: {
    showFoto(foto) {
      this.fotoAbsen = foto;
      this.dialogPhoto = true;
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    changeFakultas() {
      this.getDataFromApi();
    },
    async getListFakultas() {
      FakultasService.fakultasAll()
        .then((res) => {
          this.items_fakultas = res.data.list_data;

          if (this.getFakultasId) {
            this.items_fakultas.forEach((entry) => {
              if (entry.id == this.getFakultasId) {
                this.selectedFakultas = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var fakultas = "";

      if (this.selectedFakultas) {
        fakultas = this.selectedFakultas.id;
      }

      this.loading = true;
      CutiService.cutiList(page, itemsPerPage, this.search, fakultas)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    // doAdd: async function () {
    //   this.dialog = true;
    //   this.currentData.nama = "";
    //   this.currentData.email = "";
    //   this.currentData.selectedFakultas = "";
    //   this.currentData.id = null;
    //   console.log("do add");
    // },

    doSave: async function () {
      if (this.currentData.id == null) {
        CutiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Admin Fakultas berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            if (err.response?.data?.code == 1) {
              CommonService.showErrorToast(err.response?.data?.message);
            } else {
              CommonService.showErrorToast(
                "Tambah Admin Fakultas gagal. Coba lagi",
              );
            }
          });
      } else {
        CutiService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Admin Fakultas berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            if (err.response?.data?.code == 1) {
              CommonService.showErrorToast(err.response?.data?.message);
            } else {
              CommonService.showErrorToast(
                "Update Admin Fakultas gagal. Coba lagi",
              );
            }
          });
      }
    },

    // editItem(item) {
    //   console.log(item);
    //   this.editedIndex = this.list_data.indexOf(item);
    //   this.editedItem = Object.assign({}, item);

    //   this.dialog = true;
    //   this.currentData.nama = this.editedItem.nama;
    //   this.currentData.email = this.editedItem.email;
    //   this.currentData.id = this.editedItem.id;

    //   if (this.editedItem.fakultas_id) {
    //     this.items_fakultas.forEach((entry) => {
    //       if (entry.id == this.editedItem.fakultas_id) {
    //         this.currentData.selectedFakultas = entry;
    //       }
    //     });
    //   }

    //   console.log("do add");
    // },

    // deleteItem(item) {
    //   console.log(item);
    //   this.editedIndex = this.list_data.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    deleteItemConfirm() {
      this.closeDelete();

      CutiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Admin Fakultas berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Admin Fakultas gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveItemConfirm() {
      CutiService.approve(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          CommonService.showSuccessToast("Approve cuti berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Approve cuti gagal. Coba lagi");
        });
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    rejectItemConfirm() {
      CutiService.reject(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          CommonService.showSuccessToast("Reject cuti berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Reject cuti gagal. Coba lagi");
        });
    },
  },
};
</script>
