import http from "../http-common";
/* eslint-disable */
class PaketMembershipService {
  paketList(page, itemPerPage, keyword) {
    return http.get(
      "/paket-membership/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  paketDetailList(page, itemPerPage, keyword, id) {
    return http.get(
      "/paket-membership-detail/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("paket_id", data.selectedTipePaket.code);
    bodyFormData.append("harga", data.harga);
    bodyFormData.append("periode", data.periode);

    return http.post("/paket-membership", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("paket_id", data.selectedTipePaket.code);
    bodyFormData.append("harga", data.harga);
    bodyFormData.append("periode", data.periode);

    return http.put(`/paket-membership/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/paket-membership/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createBenefit(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("tipe_benefit", data.selectedTipeBenefit.code);
    bodyFormData.append("value", data.value);
    bodyFormData.append("paket_membership_id", data.paket_membership_id);

    return http.post("/paket-membership-detail", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateBenefit(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("tipe_benefit", data.selectedTipeBenefit.code);
    bodyFormData.append("value", data.value);
    bodyFormData.append("paket_membership_id", data.paket_membership_id);

    return http.put(`/paket-membership-detail/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteBenefit(id) {
    return http.delete(`/paket-membership-detail/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PaketMembershipService();
